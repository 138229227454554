import React, { useState, useEffect, useRef } from 'react';
import Draggable from 'react-draggable';
import { Icon } from 'semantic-ui-react';

const DraggableWindow = ({ isVisible, onClose, children, title }) => {
    const windowRef = useRef(null);
    const [position, setPosition] = useState({ x: 0, y: 0 });

    const handleDrag = (e, ui) => {
        const { x, y } = ui;
        setPosition({ x, y });
    };

    useEffect(() => {

        const handleKeyPress = (e) => {
            if (e.key === 'Escape') {
                onClose();
            }
        };

        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, [onClose]);

    return isVisible ? (
        <div className="tw-fixed tw-z-50 tw-left-1/2 tw-top-1/4 -tw-translate-x-1/2 tw-h-0">
            <Draggable
                handle=".window"
                position={position}
                onDrag={handleDrag}
            >
                <div
                    ref={windowRef}
                    className="tw-z-50"
                >
                    <div className="window tw-absolute tw-shadow-lg tw-flex tw-flex-row tw-justify-between tw-cursor-move tw-h-10 tw-p-2 tw-rounded-xl tw-w-full tw-bg-[#666] -tw-top-12">
                        <p className='tw-text-white'>{title}</p>
                        <Icon link name='times' size='small' circular inverted color='red' onTouchStart={onClose} onClick={onClose} />
                    </div>
                    {children}
                </div>
            </Draggable>
        </div>
    ) : null;
};

export default DraggableWindow;
