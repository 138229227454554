import React from 'react';
import { List } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import { Styles, Theme } from '../../res';

const { f_12_400_text, f_12_700_text } = Styles;

const ContactList = ({ size, style }) => {
    const { t } = useTranslation();
    return (
        <List size={size || 'mini'} animated >
            {/* <List.Item
                // icon={<Flag name='ca' />}
                content={<a style={{...f_12_400_text,color:Theme.colors.WHITE}} href='tel:+14389987091'><div style={{ direction: 'ltr' }}>+1 438 998-7091</div></a>}
            /> */}
            <List.Item
                // icon={<Flag name='iq' />}
                content={<a style={style || { ...f_12_400_text, color: Theme.colors.WHITE }} href='tel:+97433665583'><div style={{ direction: 'ltr' }}>+974 3366-5583 <span style={{ ...f_12_700_text, color: Theme.colors.PRIMARY_60 }}>({t("PhoneSales")})</span></div></a>}
            />
            <List.Item
                // icon={<Flag name='iq' />}
                content={<a style={style || { ...f_12_400_text, color: Theme.colors.WHITE }} href='tel:+97433663877'><div style={{ direction: 'ltr' }}>+974 3366-3877 <span style={{ ...f_12_700_text, color: Theme.colors.PRIMARY_60 }}>({t("PhoneSupport")})</span></div></a>}
            />
        </List>
    );
};

export default ContactList;