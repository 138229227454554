import React, { useState } from 'react';
import { Accordion, Container, Form, FormCheckbox, FormGroup, Icon, Header, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import { Consts, Theme } from '../../../res';

const filterTypes = {
    UNATTEMPTED: "UNATTEMPTED",
    ATTEMPTED: "ATTEMPTED",
    FLAGGED: "FLAGGED"
};

const ExamSummary = (props) => {

    const { questions, onSelectQuestion, onFlagQuestion,
        questionsFilter, isViewOnly,
        summary, t, isMockExam, theme, course } = props;

    const [filters, setFilters] = useState([]);
    const [activeIndex, setActiveIndex] = useState(0);

    const handleClick = (e, titleProps) => {
        const { index } = titleProps;
        const newIndex = activeIndex === index ? -1 : index;
        setActiveIndex(newIndex);
    };

    // const printQuestionState = (q) => {
    //     if (!q.answerText && !q.answerOptionId && !q.answerJson) return t('NotAnsweredQuestion');
    //     if (q.answerOptionId && q.correctAnswer && !isMockExam && q.correctAnswer.correctAnswerOptionId === q.answerOptionId)
    //         return t('CorrectAnswer');
    //     else if (q.answerOptionId && q.correctAnswer && !isMockExam && q.correctAnswer.correctAnswerOptionId !== q.answerOptionId)
    //         return t('WrongAnswer');
    //     else if (q.answerOptionId && !q.correctAnswer && !isMockExam)
    //         return t('ClickToLoadQuestion');
    //     return t('Answered');
    // };

    const handleChange = (value) => {
        if (filters.includes(value)) return setFilters(prev => prev.filter(f => f !== value));
        return setFilters(prev => [...prev, value]);
    };

    return (
        <Segment basic style={{ overflowY: 'scroll', maxHeight: '70vh' }}>
            <Container style={{ width: '80vw' }}>

                {isViewOnly && <Form>
                    <FormGroup inline>
                        <label>{t("FilterBy")}</label>
                        <FormCheckbox
                            label={t("Unattempted")}
                            value={filterTypes.UNATTEMPTED}
                            checked={filters.includes(filterTypes.UNATTEMPTED)}
                            onChange={() => handleChange(filterTypes.UNATTEMPTED)}
                        />
                        <FormCheckbox
                            label={t("Attempted")}
                            value={filterTypes.ATTEMPTED}
                            checked={filters.includes(filterTypes.ATTEMPTED)}
                            onChange={() => handleChange(filterTypes.ATTEMPTED)}
                        />
                        <FormCheckbox
                            label={t("Flagged")}
                            value={filterTypes.FLAGGED}
                            checked={filters.includes(filterTypes.FLAGGED)}
                            onChange={() => handleChange(filterTypes.FLAGGED)}
                        />
                        {filters.length > 0 && <label className='tw-cursor-pointer' onClick={() => setFilters([])}>{t("Clear")}</label>}
                    </FormGroup>
                </Form>}

                <Header textAlign='center'>{t("ExamReview")}</Header>

                {!isViewOnly && <Accordion fluid style={{ border: 'none', boxShadow: 'none' }} >
                    <Accordion.Title
                        active={activeIndex === 0}
                        index={0}
                        onClick={handleClick}
                    >
                        <div className='tw-text-white tw-bg-CIA_MAIN tw-p-2 tw-flex tw-flex-row tw-items-center tw-gap-5'>
                            <div><Icon size='large' link name={activeIndex === 0 ? 'minus square outline' : 'plus square outline'} /></div>
                            <p className='tw-font-bold'>{t("Instructions")}</p>
                        </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 0} content={
                        <div className='tw-border tw-border-CIA_MAIN tw-p-5 tw-grid tw-gap-5'>
                            <p>{t("CiaSummaryInstructionsText1")}</p>
                            <p>{t("CiaSummaryInstructionsText2")}</p>
                            <ul>
                                <li>{t("CiaSummaryInstructionsText3")}</li>
                                <li>{t("CiaSummaryInstructionsText4")}</li>
                                <li>{t("CiaSummaryInstructionsText5")}</li>
                            </ul>
                            <p>{t("CiaSummaryInstructionsText6")}</p>
                        </div>
                    } />
                </Accordion>}

                <Accordion fluid style={{ border: 'none', boxShadow: 'none' }} >
                    <Accordion.Title
                        active={activeIndex === 1}
                        index={1}
                        onClick={handleClick}
                    >
                        <div className='tw-text-white tw-bg-CIA_MAIN tw-p-2 tw-flex tw-flex-row tw-items-center tw-justify-between'>
                            <div className='tw-flex tw-flex-row tw-items-center tw-gap-5'>
                                <Icon size='large' link name={activeIndex === 1 ? 'minus square outline' : 'plus square outline'} />
                                <p className='tw-font-bold'>{`${course.certificateName}`}</p>
                            </div>
                            <div>
                                <p className='tw-font-bold'>{`${t("QuestionsCount")} : ${questions.length} (${questionsFilter === "ALL" ? t("All") : questionsFilter === "FLAGGED" ? t("Flagged") : t("Unattempted")})`}</p>
                            </div>
                        </div>
                    </Accordion.Title>
                    <Accordion.Content active={activeIndex === 1} content={
                        <div className='tw-border tw-border-CIA_MAIN tw-p-5 tw-grid tw-gap-5'>
                            <div className='tw-grid tw-grid-cols-3'>
                                {
                                    questions.filter(q => !(q?.showFactOnly)).map((q, indx) => {
                                        const isFlagged = q.isFlagged;
                                        const isAttempted = q?.answerOptionId || q?.answerText || q?.answerJson;
                                        const isUnAttempted = !isAttempted;
                                        const isWrongAnswerd = q?.answerOptionId && q?.correctAnswer && !isMockExam && q.correctAnswer.correctAnswerOptionId !== q.answerOptionId;

                                        if (
                                            filters.length === 0
                                            || (filters.includes(filterTypes.FLAGGED) && isFlagged)
                                            || (filters.includes(filterTypes.ATTEMPTED) && isAttempted)
                                            || (filters.includes(filterTypes.UNATTEMPTED) && isUnAttempted)
                                        )
                                            return <div
                                                key={q.questionId}
                                                style={{ cursor: 'pointer', color: isWrongAnswerd ? Theme.colors.ERROR_50 : (isAttempted && Theme.colors.SECONDARY_50) }}
                                            >
                                                <div
                                                    className='tw-border tw-p-2'
                                                    onClick={() => {
                                                        onSelectQuestion(q);
                                                        if (window.ExamNav)
                                                            window.ExamNav.goTo(Number(indx));
                                                        summary.setShowSummary(false);
                                                    }}
                                                >
                                                    <div className='tw-flex tw-flex-row tw-items-center tw-gap-5'>
                                                        <div><Icon link name='flag' onClick={() => onFlagQuestion(q)} style={{ color: q.isFlagged ? (theme === Consts.EXAM_THEMES.CMA ? '#8CC63F' : '#639FD2') : isAttempted ? 'black' : '#ddd' }} /></div>
                                                        <p>{`${t('Question')} ${indx + 1}`}</p>
                                                    </div>
                                                </div>

                                            </div>;
                                        else return null;
                                    })
                                }
                            </div>
                        </div>
                    } />
                </Accordion>
            </Container>
        </Segment >
    );
};

export default withTranslation()(ExamSummary);