import 'devextreme/dist/css/dx.common.css';
import 'devextreme/dist/css/dx.material.blue.light.compact.css';
import 'react-toastify/dist/ReactToastify.min.css';
import React from 'react';
import { Provider } from 'react-redux';
import { I18nextProvider } from 'react-i18next';
import { ToastContainer } from 'react-toastify';
import { ProSidebarProvider } from 'react-pro-sidebar';
import cookies from 'react-cookies';
import { CustomProvider } from 'rsuite';

import i18n from './i18n';
import Main from './Main';
import { mediaStyle, MediaContextProvider } from './Media';
import { store } from './store';

const App = () => {
  const lng = cookies.load('languagePref') || 'en';
  const isArabic = lng === 'ar';
  return (
    <ProSidebarProvider>
      <style>{mediaStyle}</style>
      <MediaContextProvider>
        <Provider store={store}>
          <I18nextProvider i18n={i18n}>
            <CustomProvider rtl={isArabic}>
              <Main />
            </CustomProvider>
            <ToastContainer rtl={isArabic} style={{ width: '25vw' }} position={!isArabic ? 'top-right' : 'top-left'} theme='light' autoClose={5000} />
          </I18nextProvider>
        </Provider>
      </MediaContextProvider>
    </ProSidebarProvider>
  );
};

export default App;
