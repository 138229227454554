import React, { useState } from 'react';
import { Header, Segment } from 'semantic-ui-react';
import { useTranslation } from 'react-i18next';
import TextEditor from '../../Generals/TextEditor';
import { HtmlEditor } from 'devextreme-react/html-editor';

const TextBlock = ({ data, saveBlock }) => {
    const { t } = useTranslation();
    const [textEn, setTextEn] = useState(data?.textEn || "");
    const [textAr, setTextAr] = useState(data?.textAr || "");


    return (
        <Segment>
            <Header>{t("EnglishText")}</Header>
            <TextEditor data={{ value: textEn, setValue: setTextEn }} showUploadImage={false} onBlur={() => saveBlock(data.blockId, { ...data, textEn })} />
            <Header>{t("ArabicText")}</Header>
            <TextEditor isArabic data={{ value: textAr, setValue: setTextAr }} showUploadImage={false} onBlur={() => saveBlock(data.blockId, { ...data, textAr })} />
        </Segment>
    );
};

export const TextBlockViewer = ({ data }) => {
    const { i18n } = useTranslation();
    const isArabic = i18n.language === 'ar';
    return (
        <HtmlEditor readOnly value={isArabic ? (data?.textAr || data?.textEn) : data?.textEn} style={{ border: 'none', boxShadow: 'none' }} />
    );
};

export default TextBlock;