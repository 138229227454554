import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Button, Divider, Form, Segment, Input } from 'semantic-ui-react';
import DataGrid, { Column, Lookup, RequiredRule } from 'devextreme-react/data-grid';
import { FilesUploader, ProfileImageUploader, TextEditor } from '..';
import request from '../../api';
import LoadingGifImage from '../../res/images/loading.gif';


const SendEmail = ({ sendTo }) => {
    const { t } = useTranslation();
    const [subject, setSubject] = useState("");
    const [msg, setMsg] = useState("");
    const [to, setTo] = useState(sendTo || "");
    const [cc, setCc] = useState("");
    const [attachments, setAttachmenets] = useState([]);
    const [loading, setLoading] = useState(false);

    const onSendMsg = async () => {
        try {
            setLoading(true);
            await request.post('/admin/message/users', {
                emails: to.split(",").filter(t => Boolean(t)).map(t => t.trim()),
                cc: cc.split(",").filter(c => Boolean(c)).map(c => c.trim()),
                mailBodyHtml: msg,
                mailSubject: subject,
                attachmentUrls: attachments.map(d => d.docUrl),
                method: 'EMAIL'
            });

        } catch (error) {
        } finally {
            setLoading(false);
        }
    };

    // const addVideo = async (details, setValue) => {
    //     await request.post('/video/new', { ...details, questionVideoType: "REQUIREMENT" });
    //     setValue(details.urlEn);
    // };

    return (
        <Segment basic>
            <Form>
                <Form.Field>
                    <Input fluid value={subject} label={t("Subject")} placeholder={t("Subject")} onChange={(e, { value }) => setSubject(value)} />
                </Form.Field>
                <Form.Field>
                    <Input fluid value={to} label={t("To")} placeholder="example1@gmail.com,example2@gmail.com,..." onChange={(e, { value }) => setTo(value)} />
                </Form.Field>
                <Form.Field>
                    <Input fluid value={cc} label={t("CC")} placeholder="example1@gmail.com,example2@gmail.com,..." onChange={(e, { value }) => setCc(value)} />
                </Form.Field>
                <Divider hidden />
                <Form.Field>
                    <TextEditor data={{ value: msg, setValue: setMsg }} isDummy />
                </Form.Field>

                <Form.Field>
                    <DataGrid
                        dataSource={attachments}
                        showBorders
                        columnHidingEnabled
                        wordWrapEnabled
                        columnAutoWidth
                        loadPanel={{ indicatorSrc: LoadingGifImage }}
                        editing={{ allowDeleting: true, allowAdding: true, mode: 'row' }}
                        onSaved={(e) => setAttachmenets(e.component.getDataSource().items())}
                        onInitNewRow={e => e.data.type = "FILE"}
                    >

                        <Column dataField="type" caption={t("Type")} setCellValue={(newData, value) => {
                            newData.type = value;
                            newData.docUrl = null;
                        }}>
                            <Lookup
                                valueExpr='value'
                                displayExpr='text'
                                dataSource={[
                                    { value: "FILE", text: t('File') },
                                    // { value: "VIDEO", text: t("Video") },
                                    { value: "IMAGE", text: t("Image") }
                                ]}
                            />
                            <RequiredRule />
                        </Column >

                        <Column dataField="docUrl"
                            alignment='center'
                            allowSorting={false}
                            allowFiltering={false}
                            caption={t('Attachment')}
                            editCellRender={({ value, setValue, data }) => data?.type === 'FILE'
                                ? <FilesUploader data={{ value, setValue }} isSingle isGeneral />
                                // : data?.type === 'VIDEO' ?
                                //     <VideoUploader data={{ value, setValue: (details) => addVideo(details, setValue), isGeneral: true }} />
                                : <ProfileImageUploader data={{ value, setValue }} />
                            }
                        />

                    </DataGrid>
                </Form.Field>
            </Form>
            <Divider hidden />
            <Button fluid icon='send' labelPosition='left' positive onClick={onSendMsg} loading={loading} content={t('Send')} />
        </Segment>
    );
};

export default SendEmail;