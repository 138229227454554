export const Theme = {
    colors: {
        WHITE: "#fff",
        BLACK: "#000",
        PRIMARY_60: "#15308C",
        PRIMARY_50: "#4362CB",
        PRIMARY_05: "#E5EBFF",
        PRIMARY_2: "#F9FAFF",
        SECONDARY_60: "#3D8E0C",
        SECONDARY_50: "#77D13E",
        SECONDARY_05: "#E1FFF9",
        SECONDARY_2: "#F2FFEA",
        GREY_90: "#393939",
        GREY_70: "#646464",
        GREY_50: "#989898",
        GREY_30: "#B5B5B5",
        GREY_10: "#E4E4E4",
        GREY_05: "#EFEFEF",
        ERROR_100: "#AA3C3C",
        ERROR_75: "#E36262",
        ERROR_50: "#FFA1A1",
        ERROR_25: "#FFCFCF",
        GOLD: "#E29605",
        CPA_ORANGE: "#d5440b",
        CPA_NAVY: "#043a4e",
        CIA_MAIN: "#0370A8"
    }
};