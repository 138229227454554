import React, { useEffect, useState } from 'react';
import { Grid, GridColumn, Segment } from 'semantic-ui-react';
import { connect } from 'react-redux';
import { userExamSelectQuestion, userExamFlagQuestion, userExamFavQuestion, userGetExamQuestions, userExamAnswerQuestion, userOnFinishExam, userExamInfoChanged, userExamHideSomeAnswers, userExamExcludeOption } from '../../actions';
import { Spinner, ErrorSegment } from '..';
import ExamHeader from './ExamBody/Header';
import QuestionPage from './ExamBody/Page';
import ExamFooter from './ExamBody/Footer';
import QuestionsNav from './ExamBody/Nav/QuestionsNav';
import Results from './ExamResults';
import ExamSummary from './ExamSummary';
import ExamStats from './ExamBody/ExamStats';
import { Consts, Styles } from '../../res';

const { noExtraSpaces } = Styles;

const Exam = (props) => {

    const { loading, error, Q, questions: allQuestions, questionOptionsLoading, questionOptionsError,
        onGoingExam, userGetExamQuestions, userExamSelectQuestion, userExamFlagQuestion, userExamFavQuestion,
        userExamAnswerQuestion, userOnFinishExam, userExamInfoChanged, isFinished, examFinishingLoading,
        selectedCourse, selectedCourseStruct, user, userExamHideSomeAnswers, userExamExcludeOption
    } = props;

    const [showExamSummary, setShowExamSummary] = useState(false);
    const [questionsFilter, setQuestionsFilter] = useState("ALL");

    const handleQuestionFilterChange = (filter) => {
        setShowExamSummary(false);
        setQuestionsFilter(filter);
        userExamSelectQuestion(questions[0]);
    };
    const questions = structuredClone(questionsFilter === "FLAGGED" ? allQuestions.filter(q => q.isFlagged) : questionsFilter === "UNATTEMPTED" ? allQuestions.filter(q => !(q?.answerOptionId || q?.answerText || q?.answerJson)) : allQuestions);

    useEffect(() => {
        const zsalesiq = document.querySelector('[data-id="zsalesiq"]');
        if (zsalesiq) zsalesiq.style.visibility = 'hidden';
        window.onbeforeunload = () => 'confirm';
        onGoingExam?.examRoundId && userGetExamQuestions(onGoingExam.examRoundId, onGoingExam);

        return () => {
            // just for mock special case
            userExamInfoChanged({ props: 'questions', value: [] });
            userExamInfoChanged({ props: 'Q', value: null });
            userExamInfoChanged({ props: 'examFinishingLoading', value: false });
            window.onbeforeunload = () => undefined;
            if (zsalesiq) zsalesiq.style.visibility = 'visible';
        };
        //eslint-disable-next-line
    }, [onGoingExam?.examType, onGoingExam?.id]);

    if (loading || !questions.length) return <Spinner />;
    if (error) return <ErrorSegment />;
    if (isFinished) return <Results onGoingExam={onGoingExam} summary={{ show: showExamSummary, setShowSummary: setShowExamSummary }} />;

    const Qindex = Q ? questions.findIndex(q => q.questionId === Q.questionId) : null;
    const facts = [];
    for (let q of questions)
        if (q.factId) facts.push(q.factId);
    const FactIndex = Q ? [...new Set(facts)].findIndex(q => q === Q.factId) : null;

    const isCMA = onGoingExam.theme === Consts.EXAM_THEMES.CMA;

    return (
        // <NoCopyPaste theme={onGoingExam.theme}>

        <div style={{ display: 'flex', flexDirection: 'column' }}>

            <ExamHeader
                course={selectedCourse}
                courseStruct={selectedCourseStruct.courseStruct}
                Qindex={Qindex}
                Q={Q}
                user={user}
                questions={questions}
                onGoingExam={onGoingExam}
                onFlagQuestion={onGoingExam.isViewOnly ? () => { } : userExamFlagQuestion}
                onFavQuestion={onGoingExam.isViewOnly ? () => { } : userExamFavQuestion}
                userOnFinishExam={userOnFinishExam}
                examFinishingLoading={examFinishingLoading}
                isMockExam={onGoingExam.isMockExam}
                summary={{ show: showExamSummary, setShowSummary: setShowExamSummary }}
                theme={onGoingExam.theme}
            />

            <Segment basic style={{ ...noExtraSpaces, height: '100%' }}>
                <Grid columns='2' style={noExtraSpaces} >
                    <GridColumn computer='2' mobile='4' style={noExtraSpaces} textAlign='center'>
                        <QuestionsNav
                            Q={Q}
                            questions={questions}
                            Qindex={Qindex}
                            onSelectQuestion={userExamSelectQuestion}
                            isViewOnly={onGoingExam.isViewOnly}
                            showNavList={isCMA}
                        />
                    </GridColumn>

                    {/* 14 : 12 cols */}
                    <GridColumn computer={isCMA ? '14' : '16'} mobile={isCMA ? '12' : '16'} style={noExtraSpaces}>
                        {showExamSummary ?
                            <ExamSummary
                                course={selectedCourse}
                                questions={questions}
                                onSelectQuestion={userExamSelectQuestion}
                                onFlagQuestion={onGoingExam.isViewOnly ? () => { } : userExamFlagQuestion}
                                onFavQuestion={onGoingExam.isViewOnly ? () => { } : userExamFavQuestion}
                                summary={{ show: showExamSummary, setShowSummary: setShowExamSummary }}
                                isMockExam={onGoingExam.isMockExam}
                                isViewOnly={onGoingExam.isViewOnly}
                                theme={onGoingExam.theme}
                                setQuestionsFilter={handleQuestionFilterChange}
                                questionsFilter={questionsFilter}
                            />
                            : <QuestionPage
                                course={selectedCourse}
                                Q={Q}
                                Qindex={Qindex}
                                FactIndex={FactIndex}
                                onAnswerQuestion={onGoingExam.isViewOnly ? () => { } : userExamAnswerQuestion}
                                loading={questionOptionsLoading}
                                error={questionOptionsError}
                                isMockExam={onGoingExam.isMockExam}
                                isViewOnly={onGoingExam.isViewOnly}
                                theme={onGoingExam.theme}
                                user={user}
                                questions={questions}
                                onGoingExam={onGoingExam}
                                onSelectQuestion={userExamSelectQuestion}
                                onFlagQuestion={onGoingExam.isViewOnly ? () => { } : userExamFlagQuestion}
                                onFavQuestion={onGoingExam.isViewOnly ? () => { } : userExamFavQuestion}
                                onHideSomeAnswers={onGoingExam.isViewOnly ? () => { } : userExamHideSomeAnswers}
                                onExcludeOption={onGoingExam.isViewOnly ? () => { } : userExamExcludeOption}
                            />
                        }
                    </GridColumn>
                </Grid>
            </Segment>

            <ExamFooter
                Q={Q}
                questions={questions}
                onSelectQuestion={userExamSelectQuestion}
                onFlagQuestion={onGoingExam.isViewOnly ? () => { } : userExamFlagQuestion}
                onFavQuestion={onGoingExam.isViewOnly ? () => { } : userExamFavQuestion}
                isMockExam={onGoingExam.isMockExam}
                isViewOnly={onGoingExam.isViewOnly}
                Qindex={Qindex}
                summary={{ show: showExamSummary, setShowSummary: setShowExamSummary }}
                userOnFinishExam={userOnFinishExam}
                examFinishingLoading={examFinishingLoading}
                onGoingExam={onGoingExam}
                theme={onGoingExam.theme}
                setQuestionsFilter={handleQuestionFilterChange}
                questionsFilter={questionsFilter}
            />

            <ExamStats
                // showNavStats
                questions={questions}
            />

        </div>
        // </NoCopyPaste>
    );
};


const mapStateToProps = ({ userExam, userCourses, auth }) => {
    const { loading, error, questions, Q, questionOptionsLoading, questionOptionsError, isFinished, examFinishingLoading } = userExam;
    const { selectedCourse, selectedCourseStruct } = userCourses;
    const { user } = auth;
    return { loading, error, questions, Q, questionOptionsLoading, questionOptionsError, isFinished, examFinishingLoading, selectedCourse, selectedCourseStruct, user };
};

export default connect(mapStateToProps, { userGetExamQuestions, userExamSelectQuestion, userExamFlagQuestion, userExamFavQuestion, userExamAnswerQuestion, userOnFinishExam, userExamInfoChanged, userExamHideSomeAnswers, userExamExcludeOption })(Exam);
