import React, { useState } from 'react';
import { Button, Modal, Segment, Icon } from 'semantic-ui-react';
import i18next from 'i18next';
import { withTranslation } from 'react-i18next';
import { ErrorSegment, VideoPlayer } from '../../..';
import { Consts, Styles, Theme } from '../../../../res';
import PresentValues from '../../../../res/pdfs/presentvalues.pdf';

const { noExtraSpaces, basicBtn } = Styles;

const ExamFooter = (props) => {
    const { Q, Qindex, isMockExam, isViewOnly, summary, setQuestionsFilter,
        onSelectQuestion, questions, t, onGoingExam, onFavQuestion, questionsFilter,
        userOnFinishExam, examFinishingLoading, additionalStyles } = props;

    const [showExplanation, setShowExplanation] = useState(false);
    const [showFinishConfirm, setShowFinishConfirm] = useState(false);
    const [showFinishAllConfirm, setShowFinishAllConfirm] = useState(false);
    const [showNextConfirm, setShowNextConfirm] = useState(false);
    const [showSummaryConfirm, setShowSummaryConfirm] = useState(false);

    const buttonStyle = {
        boxShadow: 'none !important',
        borderRadius: 0,
        borderLeft: i18next.language === 'ar' ? 'solid' : 'none',
        borderRight: i18next.language !== 'ar' ? 'solid' : 'none',
        backgroundColor: 'transparent',
        color: 'white'
    };

    const checkForScrollbar = () => {
        const scrolableElements = document.getElementsByClassName('question-body');
        const scrolableElement = scrolableElements.length > 0 ? scrolableElements[0] : null;
        if (scrolableElement && scrolableElement.scrollHeight - scrolableElement.clientHeight > 100) {
            return true;
        }
        return false;
    };

    if (!Q) return null;

    const flaggedQuestions = questions.filter(q => q.isFlagged).length;
    const unattemptedQuestions = questions.filter(q => !(q?.answerOptionId || q?.answerText || q?.answerJson)).length;

    return (
        <Segment inverted basic style={{ ...noExtraSpaces, backgroundColor: "#00B0F0", ...additionalStyles, position: 'absolute', left: 0, bottom: 0, width: '100%' }}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
                <div style={noExtraSpaces}>
                    <a title={t('Attachments')} href={PresentValues} rel='noreferrer' target='_blank'>
                        <Button icon='attach' style={buttonStyle} />
                    </a>
                    {!summary.show && ! <Button title={t('Fav')} icon={Q.isFav ? "star" : "star outline"} onClick={() => onFavQuestion(Q)} style={buttonStyle} />}
                    {((Qindex >= questions.length - 1) || isViewOnly || questionsFilter != "ALL") && !summary.show && <Button content={t('ReviewAnswers')} onClick={() => summary.setShowSummary(!summary.show)} style={buttonStyle} />}

                    {summary.show && !isViewOnly && <Button.Group>
                        <Button onClick={() => setShowSummaryConfirm("ALL")} labelPosition='left' icon='flag checkered' content={t('ReviewAll')} style={buttonStyle} />
                        {unattemptedQuestions > 0 && <Button onClick={() => setShowSummaryConfirm("UNATTEMPTED")} labelPosition='left' icon='delete' content={t('ReviewUnAttempted')} style={buttonStyle} />}
                        {flaggedQuestions > 0 && <Button onClick={() => setShowSummaryConfirm("FLAGGED")} labelPosition='left' icon='flag' content={t('ReviewFlagged')} style={buttonStyle} />}
                    </Button.Group>}
                </div>

                <div textAlign='center' style={noExtraSpaces}>
                    {!summary.show && !isMockExam && (Q.answerText || Q.answerOptionId || isViewOnly) ? <Button icon='video play' labelPosition='left' content={t('Explanation')} onClick={() => setShowExplanation(true)} style={buttonStyle} /> : null}
                </div>

                <div textAlign='center' style={noExtraSpaces}>
                    {!summary.show && <Button.Group>
                        <Button disabled={Qindex <= 0} onClick={() => onSelectQuestion(questions[Qindex - 1])} labelPosition='left' icon='arrow left' content={t('Back').toUpperCase()} style={buttonStyle} />
                        <Button disabled={Qindex >= questions.length - 1} onClick={() => checkForScrollbar() ? setShowNextConfirm(true) : onSelectQuestion(questions[Qindex + 1])} labelPosition='right' icon='arrow right' content={t('Next').toUpperCase()} style={buttonStyle} />
                    </Button.Group>}

                    {summary.show &&
                        <Button
                            content={t(onGoingExam.isViewOnly ? 'Exit' : 'Finish').toUpperCase()}
                            loading={examFinishingLoading}
                            onClick={() => onGoingExam.isViewOnly ? userOnFinishExam(onGoingExam) : (unattemptedQuestions > 0 ? setShowFinishConfirm(true) : setShowFinishAllConfirm(true))}
                            style={buttonStyle}
                        />
                    }
                </div>
            </div>

            <Modal
                onClose={() => setShowExplanation(false)}
                open={showExplanation}
                content={Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length ? <VideoPlayer kind={Consts.VIDEOS_KINDS.EXPLANATION} videoDetails={{ ...Q.correctAnswer.explanations.videos[0], ...Q }} onClose={() => setShowExplanation(false)} /> : <ErrorSegment text={t('ComingSoon')} icon='wait' />}
                basic={Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length ? true : false}
                size='large'
                centered
                closeIcon={!(Q && Q.correctAnswer && Q.correctAnswer.explanations && Q.correctAnswer.explanations.videos.length)}
                closeOnDimmerClick={false}
            />

            <Modal
                size='mini'
                open={showNextConfirm}
                header={<div className='tw-text-white tw-border-b tw-bg-CIA_MAIN tw-p-2 tw-flex tw-flex-row tw-items-center tw-justify-between'>
                    <p>{t('InvisibleContent')}</p>
                    <div><Icon link name='window close outline' onClick={() => setShowNextConfirm(false)} /></div>
                </div>}
                content={<div className='tw-text-white tw-place-items-center tw-bg-CIA_MAIN tw-p-5'>
                    <div className='tw-flex tw-flex-row tw-gap-5 tw-items-center'>
                        <div><Icon name='exclamation circle' size='big' /></div>
                        <p>{t("CiaMakeSureScrollDown")}</p>
                    </div>
                    <div className='tw-my-5'>
                        <Button onClick={() => { setShowNextConfirm(false); onSelectQuestion(questions[Qindex + 1]); }} content={t("OK")} style={{ ...basicBtn, color: 'white', boxShadow: `0 0 0 1px ${Theme.colors.WHITE}` }} />
                    </div>
                </div>}
            />

            <Modal
                size='mini'
                open={showSummaryConfirm}
                header={<div className='tw-text-white tw-border-b tw-bg-CIA_MAIN tw-p-2 tw-flex tw-flex-row tw-items-center tw-justify-between'>
                    <p>{t('StartReview')}</p>
                    <div><Icon link name='window close outline' onClick={() => setShowSummaryConfirm(false)} /></div>
                </div>}
                content={<div className='tw-text-white tw-place-items-center tw-bg-CIA_MAIN tw-p-5'>
                    <div className='tw-flex tw-flex-row tw-gap-5 tw-items-center'>
                        <div><Icon name='exclamation circle' size='big' /></div>
                        <div>
                            <p>{`${t("QuestionsCount")}: ${showSummaryConfirm === "FLAGGED" ? flaggedQuestions : showSummaryConfirm === "UNATTEMPTED" ? unattemptedQuestions : questions.length}`}</p>
                            <p>{t("ClickNextToStartReview")}</p>
                        </div>
                    </div>
                    <div className='tw-my-5'>
                        <Button onClick={() => { setQuestionsFilter(showSummaryConfirm); setShowSummaryConfirm(false); }} content={t("Next")} style={{ ...basicBtn, color: 'white', boxShadow: `0 0 0 1px ${Theme.colors.WHITE}` }} />
                    </div>
                </div>}
            />


            <Modal
                size='mini'
                open={showFinishConfirm}
                header={<div className='tw-text-white tw-border-b tw-bg-CIA_MAIN tw-p-2 tw-flex tw-flex-row tw-items-center tw-justify-between'>
                    <p>{t('AreYouSureFinishExam')}</p>
                    <div><Icon link name='window close outline' onClick={() => setShowFinishConfirm(false)} /></div>
                </div>}
                content={<div className='tw-text-white tw-place-items-center tw-bg-CIA_MAIN tw-p-5'>
                    <div className='tw-flex tw-flex-row tw-gap-5 tw-items-center'>
                        <div><Icon name='exclamation circle' size='big' /></div>
                        <p>{t("CiaFinishExamUnattempted", { count: unattemptedQuestions })}</p>
                    </div>
                    <div className='tw-my-5'>
                        <Button onClick={() => { setShowFinishAllConfirm(true); setShowFinishConfirm(false); }} content={t("YES")} style={{ ...basicBtn, color: 'white', boxShadow: `0 0 0 1px ${Theme.colors.WHITE}` }} />
                        <Button onClick={() => setShowFinishConfirm(false)} content={t("NO")} style={{ ...basicBtn, color: 'white', boxShadow: `0 0 0 1px ${Theme.colors.WHITE}` }} />
                    </div>
                </div>}
            />

            <Modal
                size='mini'
                open={showFinishAllConfirm}
                header={<div className='tw-text-white tw-border-b tw-bg-CIA_MAIN tw-p-2 tw-flex tw-flex-row tw-items-center tw-justify-between'>
                    <p>{t('AreYouSureFinishExam')}</p>
                    <div><Icon link name='window close outline' onClick={() => setShowFinishAllConfirm(false)} /></div>
                </div>}
                content={<div className='tw-text-white tw-place-items-center tw-bg-CIA_MAIN tw-p-5'>
                    <div className='tw-flex tw-flex-row tw-gap-5 tw-items-center'>
                        <div><Icon name='exclamation circle' size='big' /></div>
                        <p>{t("CiaFinishExam")}</p>
                    </div>
                    <div className='tw-my-5'>
                        <Button onClick={() => { userOnFinishExam(onGoingExam); setShowFinishAllConfirm(false); }} content={t("YES")} style={{ ...basicBtn, color: 'white', boxShadow: `0 0 0 1px ${Theme.colors.WHITE}` }} />
                        <Button onClick={() => setShowFinishAllConfirm(false)} content={t("NO")} style={{ ...basicBtn, color: 'white', boxShadow: `0 0 0 1px ${Theme.colors.WHITE}` }} />
                    </div>
                </div>}
            />
        </Segment>
    );
};

export default withTranslation()(ExamFooter);