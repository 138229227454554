import React from 'react';
import { useStopwatch } from 'react-timer-hook';
import TimerStyled from './TimerStyled';
import { Button } from 'semantic-ui-react';

const Stopwatch = ({ offsetTimestamp, theme, onPauseChanged, isAutoStart }) => {
    const { seconds, minutes, hours, days, isRunning, start, pause } = useStopwatch({ autoStart: isAutoStart, offsetTimestamp });

    const pauseClick = () => {
        if (isRunning) {
            pause();
            if (onPauseChanged)
                onPauseChanged(true);
        }
        else {
            start();
            if (onPauseChanged)
                onPauseChanged(false);
        }
    };

    return (
        <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }} >
            <TimerStyled type='STOPWATCH' theme={theme} seconds={seconds} minutes={minutes} hours={hours} days={days} />
            <div>
                <Button icon={isRunning ? 'pause' : 'play'} size='tiny' circular style={{ margin: '5px' }} onClick={pauseClick} />
            </div>
        </div>
    );
};

export default Stopwatch;

