import React, { useState } from 'react';
import { Container, Header, Icon, Segment } from 'semantic-ui-react';
import { withTranslation } from 'react-i18next';
import Stopwatch from '../../Timers/Stopwatch';
import Countdown from '../../Timers/Countdown';
import { Styles } from '../../../../res';
import { Calculator, FlexWindow, Separator } from '../../..';
import { getExamTypeString } from '../../../../helpers/examHelpers';
import request from '../../../../api';

const { noExtraSpaces } = Styles;

const ExamHeader = (props) => {
    const { t, course, courseStruct, user,
        Q, Qindex, questions, onFlagQuestion,
        onGoingExam, userOnFinishExam, isMockExam, summary, theme } = props;

    const [showTimer, setShowTimer] = useState(true);
    const [showCalculator, setShowCalculator] = useState(false);


    const getQUnitTopic = () => {
        let unitName = '', topicName = '';
        for (let i = 0; i < courseStruct.length; i++) {
            for (let j = 0; j < courseStruct[i].topics.length; j++) {
                if (courseStruct[i].topics[j].id === Q.topicId) {
                    topicName = `${courseStruct[i].topics[j].name} (${courseStruct[i].topics[j].rank})`;
                    unitName = `${courseStruct[i].name} (${courseStruct[i].rank})`;
                    return { unitName, topicName };
                }
            }
        }
        return { unitName, topicName };
    };

    const onPauseTimer = (isPause) => {
        const examRoundId = onGoingExam.examRoundId;

        request.post('/student/round/exam/pause', { examRoundId, isPause });
    };

    const renderTimer = () => {

        if (onGoingExam.isViewOnly)
            return <Header as='h2' color='red' textAlign='center'>{t('ViewOnly')}</Header>;

        return (
            <>
                <div onClick={() => setShowTimer(p => !p)} className={`${showTimer ? "tw-hidden" : "tw-flex"} tw-flex-col tw-cursor-pointer tw-justify-center tw-gap-1 tw-items-center`}>
                    <div><Icon size='large' name='clock outline' /></div>
                    <div><Icon size='large' name='tasks' /></div>
                </div>

                <div onClick={() => setShowTimer(p => !p)} style={{ display: showTimer ? 'flex' : 'none', flexDirection: 'column', justifyContent: 'center', cursor: 'pointer' }}>
                    {isMockExam ?
                        <Countdown
                            expiryTimestamp={onGoingExam.remainTime}
                            onExpire={() => userOnFinishExam(onGoingExam)}
                            theme={theme}
                        />
                        : <Stopwatch
                            offsetTimestamp={new Date().getTime() + (new Date().getTime()
                                - new Date(onGoingExam.startDateTime).getTime())
                                - (onGoingExam.totalPauseSeconds * 1000)
                                - (onGoingExam.lastPauseTime ? (new Date().getTime() - new Date(onGoingExam.lastPauseTime).getTime()) : 0)
                            }
                            theme={theme}
                            onPauseChanged={onPauseTimer}
                            isAutoStart={!onGoingExam.lastPauseTime}
                        />}
                    <Header inverted as='h5' style={noExtraSpaces}>{`${t('Question')}: ${Qindex + 1} / ${questions.length}`}</Header>
                    <Separator h='1vh' />
                </div>

            </>
        );
    };

    const renderPrimaryHeader = () => {
        return (
            <Segment basic style={{ margin: 0, padding: 5, backgroundColor: '#0370A8' }} inverted >
                <Container style={{ width: '95vw' }}>
                    <div style={{ textAlign: 'center', display: 'flex', flexDirection: ' row', justifyContent: 'space-between' }}>

                        <div style={noExtraSpaces}>
                            <Header inverted as='h5' style={noExtraSpaces}>{`${t('Name')}: ${user.firstName} ${user.lastName}`}</Header>
                            <Header inverted as='h5' style={noExtraSpaces}>{`${user.email}`}</Header>
                        </div>

                        <div style={noExtraSpaces}>
                            <Header inverted as='h5' style={noExtraSpaces}>{`${course.certificateName} (${course.certificateCode})`}</Header>
                            <Header inverted as='h5' style={noExtraSpaces}>{course.name}</Header>
                        </div>

                        <div style={noExtraSpaces}>
                            {renderTimer()}
                        </div>

                    </div>
                </Container>
            </Segment>
        );
    };

    const renderSecondaryHeader = () => {
        const { unitName, topicName } = getQUnitTopic();
        if (summary.show) return null;
        return (
            <Segment basic style={{ margin: 0, padding: 5, backgroundColor: '#6EABD0' }} inverted>
                <Container style={{ width: '95vw' }}>
                    <div style={{ textAlign: 'center', display: 'flex', flexDirection: ' row', justifyContent: 'space-between' }} >

                        <div onClick={() => setShowCalculator(true)}>
                            <Icon size='big'
                                link name="calculator"
                                style={{ color: 'white' }}
                            />
                            <span
                                style={{ marginInline: 10, cursor: 'pointer' }}>
                                {t('Calculator')}
                            </span>
                        </div>

                        <div style={noExtraSpaces}>
                            {!isMockExam && !summary.show && <Header inverted as='h5' style={noExtraSpaces}>{unitName}</Header>}
                            {!isMockExam && !summary.show && <Header inverted as='h5' style={noExtraSpaces}>{topicName}</Header>}
                        </div>

                        <div style={noExtraSpaces}>
                            {isMockExam && <Header inverted as='h5' style={noExtraSpaces}>{getExamTypeString(onGoingExam.examType, onGoingExam.orderNum)}</Header>}
                        </div>

                        <div style={noExtraSpaces}>
                            {!summary.show &&
                                <div style={{ display: 'flex', flexDirection: 'row', gap: 5, alignItems: 'center' }}>
                                    <div onClick={() => onFlagQuestion(Q)}>
                                        <Icon size='big'
                                            link name={Q.isFlagged ? 'flag' : 'flag outline'}
                                            style={{ color: Q.isFlagged ? 'yellow' : 'white', transform: 'rotate(20deg)' }}
                                        />
                                        <span
                                            style={{ textDecoration: Q.isFlagged ? 'line-through' : 'none', marginInline: 10, cursor: 'pointer' }}>
                                            {t('MarkFlag')}
                                        </span>
                                    </div>

                                </div>
                            }
                        </div>
                    </div>
                </Container>
            </Segment >
        );
    };

    if (!Q) return null;

    return (
        <Segment basic style={noExtraSpaces}>
            {renderPrimaryHeader()}
            {renderSecondaryHeader()}

            {showCalculator && <FlexWindow
                isVisible={showCalculator}
                onClose={() => setShowCalculator(false)}
                title={t("Calculator")}
            >
                <Calculator />
            </FlexWindow>}
        </Segment>
    );
};

export default withTranslation()(ExamHeader);