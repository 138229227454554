import React from 'react';
import DefaultSummary from './DefaultSummary';
import CiaSummary from './CiaSummary';
import { Consts } from '../../../res';

const Summary = (props) => {

    const renderSummary = () => {
        switch (props.theme) {
            case Consts.EXAM_THEMES.CIA:
                return <CiaSummary {...props} />;
            default:
                return <DefaultSummary {...props} />;
        }
    };

    return renderSummary();
};

export default Summary;