import React, { useState } from 'react';
import { Input, Icon } from 'semantic-ui-react';
import { pdfjs } from 'react-pdf';
import { Document, Page } from 'react-pdf';
import 'react-pdf/dist/esm/Page/AnnotationLayer.css';
import 'react-pdf/dist/esm/Page/TextLayer.css';
// import pdfFile from '../../../res/pdfs/Information.pdf'

pdfjs.GlobalWorkerOptions.workerSrc = `${process.env.PUBLIC_URL}/js/pdf.worker.min.js`;

const TbsPdfReader = ({ docUrl, showTools = true }) => {
    const [numPages, setNumPages] = useState(null);
    const [scale, setScale] = useState(1);
    const onDocumentLoadSuccess = (pdf) => {
        setNumPages(pdf.numPages);
    };

    return (
        <>
            {showTools && <div style={{ backgroundColor: '#ccc' }} className='zoom-bar' >
                <Icon
                    style={{ margin: '5px' }}
                    name='search plus'
                    onClick={() => setScale(scale + 0.1)}
                    color='grey'
                />
                <Input
                    style={{ margin: '5px' }}
                    type='number'
                    min={25} max={1000}
                    value={(Number(scale * 100)).toFixed(0)}
                    onChange={(_, { value }) => setScale(value / 100)}
                />
                <Icon
                    style={{ margin: '5px' }}
                    name='search minus'
                    onClick={() => setScale(scale - 0.1)}
                    color='grey'
                />
            </div>}

            <div className='box'>
                <Document
                    file={docUrl}
                    onLoadSuccess={onDocumentLoadSuccess}
                    options={{
                        disableFontFace: true
                    }}
                >
                    {numPages && Array.from(
                        new Array(numPages),
                        (el, index) => (
                            <Page
                                key={`page_${index + 1}`}
                                pageNumber={index + 1}
                                scale={scale}
                            />
                        ),
                    )}
                </Document>
            </div>
        </>
    );
};

export default TbsPdfReader;