import React, { useEffect, useState } from 'react';
import { Segment, Modal, Icon, Button } from 'semantic-ui-react';
import { Rnd } from 'react-rnd';
import { useTranslation } from 'react-i18next';
import request from '../../../api';
import { replaceFontFamily } from '../../../helpers/general';
import { Theme, Styles } from '../../../res';
import resizeIcon from '../../../res/images/resize.png';

const { basicBtn } = Styles;

const QuestionTranslationModal = ({ lang, question, examRoundId, show, setShow }) => {
    const { t } = useTranslation();
    const [questionHeader, setQuestionHeader] = useState('');
    const [questionBody, setQuestionBody] = useState('');

    useEffect(() => {
        setQuestionHeader('');
        setQuestionBody('');
        if (show && question) {
            request.get(`/student/round/exam/questions/${examRoundId}?questionId=${question.questionId}&lang=${lang}`)
                .then(d => {
                    request.get(`/student/question/${question.questionId}/options?lang=${lang}`)
                        .then(
                            qd => {
                                const alphArray = lang === 'ar' ? ['أ', 'ب', 'جـ', 'د', 'هـ', 'و', 'ز', 'حـ', 'ط', 'ي', 'ك', 'ل', 'م', 'ن', 'س'] : ['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J', 'K', 'L', 'M', 'N', 'O'];
                                let body = '';
                                let options = qd.data.data;
                                let index = 0;

                                for (const element of question.options) {
                                    let item = options.find(x => x.id === element.id);
                                    body += `<div style="direction:${lang === "ar" ? "rtl" : "ltr"};" class="tw-my-6" "><span>${alphArray[index++]}.</span> ${item.contents[0].content}</div>`;
                                }

                                setQuestionHeader(d.data.data[0].questionText?.replaceAll('<p>', `<p style="direction:${lang === "ar" ? "rtl" : "ltr"};">`));
                                setQuestionBody(body);
                                document.body.classList.remove('dimmable');
                                document.body.classList.remove('dimmed');
                            }
                        );

                });
        }
        //eslint-disable-next-line
    }, [show]);

    if (!show) return null;
    return (
        <Modal
            style={{ textAlign: lang === 'en' ? 'left' : 'right', margin: '5px' }}
            open={show}
            content={
                <Rnd
                    default={{ x: 0, y: 0, width: 400, height: 250 }}
                    minHeight={250}
                    maxHeight={"75vh"}
                    minWidth={400}
                    maxWidth={"80vw"}
                >
                    <div className='tw-h-full tw-flex tw-flex-col tw-relative'>
                        <img src={resizeIcon} className='tw-absolute tw-bottom-0 tw-invert tw-right-0 tw-w-8' />
                        <img src={resizeIcon} className='tw-absolute tw-bottom-0 tw-invert tw-rotate-90 tw-left-0 tw-w-8' />

                        <div className='tw-text-white tw-h-[40px] tw-border-b tw-bg-CIA_MAIN tw-p-2 tw-flex tw-flex-row tw-items-center tw-justify-between'>
                            <p>{lang === 'en' ? t('English') : t('Arabic')}</p>
                            <div><Icon link name='window close outline' onClick={() => setShow(false)} /></div>
                        </div>
                        <div className='tw-bg-CIA_MAIN tw-p-5 tw-flex tw-flex-col tw-h-[calc(100%-40px)]'>
                            <div className='tw-bg-white tw-flex tw-flex-col tw-h-full tw-grow-1 tw-overflow-auto'>
                                <div dangerouslySetInnerHTML={{ __html: replaceFontFamily(questionHeader?.trim()) }} style={{ padding: 10, direction: lang === 'ar' ? 'rtl' : 'ltr' }} />
                                <Segment basic dangerouslySetInnerHTML={{ __html: questionBody }}></Segment>
                            </div>
                            <div className='tw-mt-5'>
                                <Button style={{ ...basicBtn, color: 'white', boxShadow: `0 0 0 1px ${Theme.colors.WHITE}` }} onClick={() => setShow(false)}>{t("Close")}</Button>
                            </div>
                        </div>
                    </div>
                </Rnd>
            }
            onClose={() => setShow(false)}
            dimmer={{ style: { backgroundColor: 'transparent' } }}
            size='mini'
            centered
            closeOnDimmerClick={false}
        />
    );
};

export default QuestionTranslationModal;