import React from 'react';
import { useTranslation } from 'react-i18next';
import { Button } from 'semantic-ui-react';

const SocialShare = ({ url, withoutEncode = false }) => {
    const { t } = useTranslation();
    const encodedURL = withoutEncode ? url : encodeURIComponent("https://learning-go.com" + url);

    return (
        <div className='tw-grid tw-grid-cols-2 tw-gap-2'>
            <div>
                <a
                    className='tw-block hover:tw-no-underline focus:tw-no-underline'
                    href={`https://www.facebook.com/sharer/sharer.php?u=${encodedURL}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button fluid color="facebook" icon="facebook" content={t("Facebook")} />
                </a>
            </div>
            <div>
                <a
                    className='tw-block hover:tw-no-underline focus:tw-no-underline'
                    href={`https://twitter.com/intent/tweet?url=${encodedURL}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button fluid color="twitter" content={t("Twitter")} />
                </a>
            </div>
            <div>
                <a
                    className='tw-block hover:tw-no-underline focus:tw-no-underline'
                    href={`https://www.linkedin.com/shareArticle?mini=true&url=${encodedURL}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button fluid color="linkedin" icon="linkedin" content={t("Linkedin")} />
                </a>
            </div>
            <div>
                <a
                    className='tw-block hover:tw-no-underline focus:tw-no-underline'
                    href={`https://t.me/share/url?url=${encodedURL}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button fluid color="blue" icon="telegram" content={t("Telegram")} />
                </a>
            </div>
            <div>
                <a
                    className='tw-block hover:tw-no-underline focus:tw-no-underline'
                    href={`https://www.instagram.com/?url=${encodedURL}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button fluid color="pink" icon="instagram" content={t("Instagram")} />
                </a>
            </div>
            <div>
                <a
                    className='tw-block hover:tw-no-underline focus:tw-no-underline'
                    href={`https://wa.me/?${encodedURL}`}
                    target="_blank"
                    rel="noopener noreferrer"
                >
                    <Button fluid color="green" icon="whatsapp" content={t("Whatsapp")} />
                </a>
            </div>

            <div className='tw-col-span-2'>
                <Button fluid basic icon='copy' content={t("CopyLink")} onClick={() => navigator.clipboard.writeText(encodedURL)} />
            </div>
        </div>
    );
};

export default SocialShare;