import i18n from 'i18next';
import {
    USER_GET_ALL_OLD_EXAMS_START,
    USER_GET_ALL_OLD_EXAMS_FAIL,
    USER_GET_ALL_OLD_EXAMS_FINISH,
    USER_GET_ALL_OLD_EXAMS
} from '../types';
import request from '../../api';

export const userGetAllOldExams = (paginations, setPaginations) => async (dispatch, getState) => {
    dispatch({ type: USER_GET_ALL_OLD_EXAMS_START });
    const { selectedCourse } = getState().userCourses;
    try {
        const { data: { data, allCount } } = await request.get(`/student/round/exam/finished/course/${selectedCourse.id}?take=${paginations.take}&skip=${paginations.skip}`);
        (!paginations.totalPages) && setPaginations({ ...paginations, totalPages: Math.ceil(allCount / paginations.take) });
        dispatch({ type: USER_GET_ALL_OLD_EXAMS, payload: data });
    } catch (err) {
        if (err.response) dispatch({ type: USER_GET_ALL_OLD_EXAMS_FAIL, payload: { props: 'body', value: i18n.t('ErrorBody') } });
        else dispatch({ type: USER_GET_ALL_OLD_EXAMS_FAIL, payload: { props: 'conn', value: i18n.t('ErrorConn') } });
    } finally {
        dispatch({ type: USER_GET_ALL_OLD_EXAMS_FINISH });
    }
};
